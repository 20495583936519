// This is just an example, this site does not really need a nav menu
export const menuItems = [
  // {
  //   name: 'services',
  //   label: 'Services',
  //   items: [
  //     { name: 'chatbots', label: 'Chat Bots' },
  //     { name: 'mobile', label: 'Mobile Apps' },
  //   ],
  // },
  // {
  //   name: 'products',
  //   label: 'Products',
  //   items: [
  //     { name: 'https://pointbee.app', label: 'Pointbee', isExternal: true },
  //     { name: 'qpon', label: 'Qpon' },
  //   ],
  // },
  // { name: 'https://pointbee.app', label: 'Pointbee', isExternal: true },
  // { name: 'qpon', label: 'Qpon' },
]
