module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-66485739-18","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dot Dot Data","short_name":"Dot Dot Data","start_url":"/","background_color":"#ffffff","theme_color":"#e96364","display":"minimal-ui","icon":"src/assets/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"bae6c86905a689b2e4d4469245210575"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
