import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import imgAS from '../../assets/image/l6-download-appstore.png'
import imgGP from '../../assets/image/l6-download-gplay.png'

const Footer = () => {
  return (
    <>
      <div className="footer-section pt-17 pt-lg-21">
        <Container>
          <div className="footer-top pb-lg-14">
            <Row>
              <Col xs="6" lg="2">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-7">Company</p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-2">
                      <Link
                        href="https://dotdotdata.com/about"
                        target="_blank"
                        rel="noreferrer"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        About us
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        href="https://dotdotdata.com/contact"
                        target="_blank"
                        rel="noreferrer"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs="6" lg="3">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-7">Legal</p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-2">
                      <Link
                        to="/privacy"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="/terms"
                        className="gr-text-9 gr-text-color gr-hover-text-red"
                      >
                        Terms of Use
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs="6" lg="3" className="offset-lg-4">
                <div className="single-footer mb-13 mb-lg-9">
                  <div className="download-block">
                    <p className="download-title gr-text-11 mb-9">
                      Start earning points
                    </p>
                    <div className="download-btns">
                      <a
                        href="https://apps.apple.com/app/id1455555114"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={imgAS}
                          alt="apple app store"
                          className="mb-5 w-100 w-xs-auto"
                        />
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.dotdotdata.pointbee"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={imgGP}
                          alt="google play store"
                          className="w-100 w-xs-auto"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="copyright-area border-top py-9">
            <Row className="align-items-center">
              <Col lg="6">
                <p className="copyright-text gr-text-11 mb-6 mb-lg-0 gr-text-color text-center text-lg-left">
                  © 2020 Copyright, Powered by Dot Dot Data
                </p>
              </Col>
              <Col lg="6" className="text-center text-lg-right">
                <ul className="social-icons list-unstyled mb-0">
                  <li className="ml-7">
                    <Link
                      href="https://twitter.com/dotdotdata"
                      className="gr-text-color gr-hover-text-red"
                      target="_blank"
                      rel="noopener"
                    >
                      <i className="icon icon-logo-twitter"></i>
                    </Link>
                  </li>
                  <li className="ml-7">
                    <Link
                      href="https://facebook.com/pointbeeapp"
                      className="gr-text-color gr-hover-text-red"
                      target="_blank"
                      rel="noopener"
                    >
                      <i className="icon icon-logo-facebook"></i>
                    </Link>
                  </li>
                  <li className="ml-7">
                    <Link
                      href="https://instagram.com/pointbee.app"
                      className="gr-text-color gr-hover-text-red"
                      target="_blank"
                      rel="noopener"
                    >
                      <i className="icon icon-instant-camera-2"></i>
                    </Link>
                  </li>
                  <li className="ml-7">
                    <Link
                      href="https://www.linkedin.com/company/dotdotdata"
                      className="gr-text-color gr-hover-text-red"
                      target="_blank"
                      rel="noopener"
                    >
                      <i className="icon icon-logo-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Footer
